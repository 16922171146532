<template>
  <div>
    <add-new-role :active.sync="isAddRole" @add-new-role="addNewRole" />
    <b-card>
      <b-row>
        <b-col xl="3" sm="12" md="6">
          <div class="list-role-header d-flex">
            <div class="role mb-1 w-50 d-flex align-items-center">
              <feather-icon class="cursor-pointer" icon="CpuIcon" size="16" />
              <p class="pl-1">
                <b>Vai trò</b>
              </p>
            </div>
            <div class="role w-50 d-flex justify-content-center">
              <!-- <feather-icon class="cursor-pointer" icon="UserIcon" size="16" /> -->
              <p>Số lượng</p>
            </div>
          </div>
          <b-card class="list-role px-2">
            <div
              v-for="(item, index) in roles"
              :key="item.id"
              class="list-role-item d-flex"
              :class="{
                'bg-primary': roleId === item.id,
                'text-white': roleId === item.id,
              }"
              @click="() => handleChooseRole(item, index)"
            >
              <div class="item w-50">
                {{ item.name }}
              </div>
              <div class="item text-center w-50">
                {{ item.count_staff }}
              </div>
            </div>
            <div
              v-if="
                $can(permissions.ROLE.POST_v1_roles.action, permissions.ROLE.POST_v1_roles.resource)
              "
              class="list-role-item"
              @click="() => (isAddRole = true)"
            >
              <p><b>+ Thêm vai trò mới</b></p>
            </div>
          </b-card>
        </b-col>
        <b-col xl="9" sm="12" md="6">
          <div class="role mb-1 w-75 d-flex align-items-center">
            <feather-icon class="cursor-pointer" icon="CommandIcon" size="16" />
            <p class="pl-1">
              <b>Quyền hạn</b>
            </p>
          </div>
          <permission-list
            v-if="roleId"
            :per-check="perOfRole"
            :role-name.sync="roleName"
            :loading="updating"
            @update-permission="updatePermission"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BButton } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import {
  roleList,
  createRole,
  getPermissionRole,
  updatePermission,
} from '@/apis/apiRole_Permission';
import permissions from '@/constants/permissions';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AddNewRole from './permission-add/RoleAdd.vue';
import PermissionList from './permission-list/PermissionList.vue';

export default {
  name: 'Permission',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    AddNewRole,
    PermissionList,
  },
  setup() {
    const roles = ref([]);
    const roleId = ref();
    const roleName = ref();
    const perOfRole = ref([]);
    const isAddRole = ref(false);
    const getPerRole = async (id) => {
      const per = await getPermissionRole(id);

      const { data } = per.data;
      return data.map((permission) => ({
        name: permission.name,
        group: permission.group,
        limited: permission.scope,
        id: permission.id,
        permissionId: permission.id,
      }));
    };
    const getPermissionOfRole = async (id) => {
      perOfRole.value = await getPerRole(id);
    };
    const getRole = () => {
      roleList()
        .then(async (res) => {
          roles.value = res.data.data;
          roleName.value = roles.value[0]?.name;
          roleId.value = res.data.data[0].id;
          await getPermissionOfRole(res.data.data[0].id);
        })
        .catch((err) => console.log(err));
    };

    getRole();
    return {
      isAddRole,
      roles,
      roleId,
      perOfRole,
      roleName,
      getRole,
      getPerRole,
      getPermissionOfRole,
      permissions,
    };
  },
  data: () => ({
    roleEditPosIndex: 0,
    updating: false,
  }),
  methods: {
    async handleChooseRole(item, index) {
      this.roleId = item.id;
      this.roleName = item.name;
      this.roleEditPosIndex = index;

      this.perOfRole = await this.getPerRole(this.roleId);
    },
    addNewRole(value) {
      createRole({
        name: value,
      })
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Tạo vai trò',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Tạo thành công!',
            },
          });
          this.getRole();
        })
        .catch((err) => {
          console.log(err.response);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Tạo vai trò thất bại',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              text: err.response.data.message,
            },
          });
        });
    },
    updatePermission(data) {
      this.updating = true;
      const payroll = {
        ...data,
        name: this.roleName,
      };
      updatePermission(this.roleId, payroll)
        .then((res) => {
          this.roles[this.roleEditPosIndex].name = this.roleName;

          this.getPermissionOfRole(this.roleId);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Cập nhật quyền hạn',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Thành công!',
            },
          });
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.data.errors?.name) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Cập nhật quyền hạn thất bại',
                icon: 'CheckCircleIcon',
                variant: 'danger',
                text: 'Tên vai trò đã tồn tại',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Cập nhật quyền hạn thất bại',
                icon: 'CheckCircleIcon',
                variant: 'danger',
                text: err.response.data.message,
              },
            });
          }
        })
        .then(() => {
          this.updating = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/base/colors.scss';
.list-role {
  &.card {
    margin-bottom: 0;
  }
}
.list-role {
  .card-body {
    padding-left: 0;
    padding-right: 0;
  }
}
.list-role-item {
  transition: 0.5s ease all;
  cursor: pointer;
  padding: 10px 15px;
  border: 0.5px solid rgba($color: #000000, $alpha: 0.2);
  border-bottom: none;
}
.list-role-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.list-role-item:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom: 0.5px solid rgba($color: #000000, $alpha: 0.2);
}
.list-role-item:hover {
  transition: 0.5s ease all;
  background-color: $primary;
  color: #fff;
  border-color: $primary;
}
</style>
